@import "../../../../index.scss";

.container {
	flex: 1;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.link {
	font-size: $link-font-size;
	color: $link-color;
	text-decoration: none;
	transition: all 0.3s ease-in-out;
	&:hover {
		color: $link-color-hover;
	}
}

.active-link {
	text-decoration: underline 3px;
	color: $link-color;
}

.inactive-link {
	color: $link-color;
}

.error-message {
	@include error-message;
}
